import styled from 'styled-components'

export const getStatusColor = ({ statusColor, theme }) => theme[statusColor]

export const LeftLine = styled.div<{
  statusColor: string
}>`
  background-color: ${getStatusColor};
  border-color: ${getStatusColor};
  width: auto;
  display: block;
  height: inherit;
  content: '';
  border-left: 4px solid ${getStatusColor};
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
`

export const Card = styled.div`
  border-top: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  border-left: none;
  border-right: 1px solid #e0e0e0;
  height: inherit;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;

  overflow: hidden;
`

export const Content = styled.div`
  padding: 16px;
  padding-bottom: 0;
`

import React, { useState } from 'react'
import { AutoCompleteProps, Input, AutoComplete } from '@hindawi/phenom-ui'
import { Option, SuggestionTypes } from './SuggestionsManager'

export interface SearchWithAutoCompleteProps extends AutoCompleteProps {
  getSuggestions: (searchTerm: string) => any
  renderLabel: (object: SuggestionTypes) => string | JSX.Element
  renderDisplayValue: (option: SuggestionTypes) => any
  renderValue: (object: SuggestionTypes) => string | JSX.Element
  selectSuggestion: (option: Option) => void
  suggestions: SuggestionTypes[]
  loading?: boolean
}

export const SearchWithAutocomplete: React.FC<SearchWithAutoCompleteProps> = ({
  getSuggestions,
  renderLabel,
  renderValue,
  renderDisplayValue,
  selectSuggestion,
  suggestions = [],
  loading,
  ...rest
}) => {
  const [dirty, setDirty] = useState(false)
  const parseOption = (suggestions) =>
    suggestions.map((option) => ({
      value: renderValue(option),
      label: renderLabel(option),
      display: renderDisplayValue(option),
    }))
  const handleSelectOption = (value, option) => {
    const { display } = option
    selectSuggestion({ value, displayValue: display })
  }

  const handleSearch = (e) => {
    setDirty(true)
    getSuggestions(e)
  }

  return (
    <AutoComplete
      {...rest}
      defaultOpen={false}
      notFoundContent={dirty && 'No suggestion available...'}
      onSearch={handleSearch}
      onSelect={handleSelectOption}
      options={parseOption(suggestions)}
    >
      <Input.Search loading={loading} />
    </AutoComplete>
  )
}

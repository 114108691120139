import React, { ReactNode } from 'react'
import { useTracking } from 'react-tracking'

export const TrackedRoute: React.FC<{
  component: React.JSX.Element | ReactNode
}> = ({ component }): ReactNode => {
  const { Track } = useTracking()

  const w = window as any
  if (w.initGTM && !w.isGTMActive) {
    w.initGTM()
  }

  return <Track>{component}</Track>
}

export default TrackedRoute

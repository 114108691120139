import './icons/icons.css'

function generateSpaces(gridUnit = 4) {
  return Array.from({ length: 200 }, (_v, i) => i * gridUnit)
}

const validation = {
  errorColor: '#FC6A4B',
  warningColor: '#F89D10',
}

export default {
  // FIXME Should be migrated to standard colors
  statusPending: '#FF5547',
  statusInvite: '#FCB624',
  statusApproved: '#0A7388',
  statusRejected: '#FF5547',
  statusInProgress: '#F89D10',
  statusWithdrawn: '#686868',

  validation,
  // properties used by styled-system
  space: generateSpaces(),
  // override pubsweet components
}

import gql from 'graphql-tag'

export const manuscriptMetaDashboard = gql`
  fragment manuscriptMetaDashboard on ManuscriptMeta {
    articleType
    title
  }
`

export const teamMemberDashboard = gql`
  fragment teamMemberDashboard on TeamMember {
    id
    status
    alias {
      email
      name {
        surname
        givenNames
      }
    }
  }
`

export const articleTypeFragment = gql`
  fragment articleTypeDetails on ArticleType {
    id
    name
  }
`

export const peerReviewEditorialMappingFragment = gql`
  fragment peerReviewEditorialMapping on PeerReviewEditorialMapping {
    id
    created
    updated
    submissionId
    peerReviewEditorialModel {
      name
      triageEditor {
        label
        enabled
      }
      academicEditor {
        label
        labelInCaseOfConflict
        autoInviteInCaseOfConflict
      }
      approvalEditor {
        enabled
        label
        decisionEditorMustApproveRevisionRequest
      }
      figureHeadEditor {
        label
        enabled
      }
      reviewerQuestions {
        name
        type
        title
        isRequired
        showOtherItem
      }
      reviewer {
        submitReportTimeFrame
        minimumReports
      }
    }
  }
`

export const authorDashboard = gql`
  fragment authorDashboard on TeamMember {
    id
    isSubmitting
    isCorresponding
    alias {
      aff
      email
      country
      name {
        surname
        givenNames
      }
    }
  }
`

export const dashboardManuscriptDetails = gql`
  fragment dashboardManuscriptDetails on Manuscript {
    id
    submissionId
    role
    status
    customId
    created
    updated
    submittedDate
    visibleStatus
    inProgress
    sourceJournalId
    commissionInvitationId
    peerReviewModel {
      academicEditorLabel
      triageEditorLabel
    }
    waiverApplied {
      type
      reason
      otherReason
    }
    journal {
      id
      name
    }
    articleType {
      ...articleTypeDetails
    }
    meta {
      ...manuscriptMetaDashboard
    }
    academicEditor {
      ...teamMemberDashboard
    }
    editorialAssistant {
      ...teamMemberDashboard
    }
    triageEditor {
      ...teamMemberDashboard
    }
    authors {
      ...authorDashboard
    }

    reviewers {
      id
      status
    }
    editors {
      id
      role
      status
    }
    specialIssue {
      id
      name
    }
    section {
      id
      name
    }
    version
    statusColor
    peerReviewEditorialMapping {
      ...peerReviewEditorialMapping
    }
    revisionDeadlineDate
    currentUserRole
  }
  ${manuscriptMetaDashboard}
  ${teamMemberDashboard}
  ${authorDashboard}
  ${articleTypeFragment}
  ${peerReviewEditorialMappingFragment}
`

export const journalDetails = gql`
  fragment journalDetails on Journal {
    id
    name
    code
    issn
    apc
    email
    activationDate
    articleTypes {
      id
      name
    }
    isActive
    peerReviewModel {
      id
      name
      hasSections
      hasTriageEditor
      triageEditorLabel
      academicEditorLabel
      hasFigureheadEditor
      figureheadEditorLabel
    }
  }
`

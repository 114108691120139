export function get<T, R>(obj: T, path: string, defaultValue: R): R {
  if (!path) {
    throw new Error('Path is required')
  }
  const pathArray = Array.isArray(path)
    ? path
    : path.replace(/\[(\d+)]/g, '.$1').split('.')

  const result = pathArray.reduce((acc, key) => {
    return acc && acc[key] !== undefined ? acc[key] : undefined
  }, obj as string)

  return result === undefined ? defaultValue : result
}

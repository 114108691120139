export const emailRegex = new RegExp(
  /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
)

export const required = (value) => {
  if (value === null || value === undefined) {
    return 'Required'
  }

  if (typeof value === 'string' && value.trim() === '') {
    return 'Required'
  }

  if (Array.isArray(value) && !value.length) {
    return 'Required'
  }

  return undefined
}

export const emailValidator = (value) =>
  emailRegex.test(value) ? undefined : 'Invalid email'

export const oneOfTheFormats =
  ({ formats, errorMessage }) =>
  (value) => {
    const entryIsValid = formats.some((format) =>
      new RegExp(format).test(value),
    )
    return entryIsValid ? undefined : errorMessage
  }

import React from 'react'
import { Tag, Button, Text, Flex } from '@hindawi/phenom-ui'
import { Author } from '@shared/ui/types'
import { Superscript } from '.'

function AuthorTagIcon({ label }: { label: string }) {
  return <Tag label={label} smallPaddingTag />
}

export function AuthorTag({
  author,
  hasStaffRole,
}: {
  author: Author
  hasStaffRole?: boolean
}): React.ReactNode {
  const formattedName = `${author.alias.name?.givenNames} ${author.alias.name?.surname}`
  return (
    <Flex
      gap={
        author.isCorresponding || author.isSubmitting
          ? '@spacer-1'
          : '@spacer-0'
      }
      className="author-tag"
      data-test-id={`author-tag-${author.id}`}
      align="center"
    >
      {hasStaffRole ? (
        <Button type="tertiary">{formattedName}</Button>
      ) : (
        <Flex minWidth="45px">
          <Text data-test-id="author-name">{formattedName}</Text>
        </Flex>
      )}
      <Flex gap="@spacer-1" align="center">
        {author.isSubmitting && (
          <AuthorTagIcon data-test-id="author-tag-submitting" label="SA" />
        )}
        {author.isCorresponding && <AuthorTagIcon label="CA" />}
        {author.affiliationNumber && (
          <Superscript>{author.affiliationNumber}</Superscript>
        )}
      </Flex>
    </Flex>
  )
}

import React from 'react'

import JournalContext from './JournalInfoContext'

function JournalProvider({
  children,
  publisher,
}: {
  children: React.ReactNode
  publisher: any
}): React.JSX.Element {
  return (
    <JournalContext.Provider value={{ ...publisher }}>
      {children}
    </JournalContext.Provider>
  )
}

export default JournalProvider

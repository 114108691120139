import { Flex, Spinner } from '@hindawi/phenom-ui'
import React from 'react'

export function LoadingState(): React.JSX.Element {
  return (
    <Flex
      width="screen"
      height="calc(100vh - 172px)"
      align="center"
      justify="center"
    >
      <Spinner />
    </Flex>
  )
}

import React, { ReactNode } from 'react'
import { get } from 'lodash'
import { Navigate, useNavigate } from 'react-router-dom'
import { Row, ShadowedBox, Text } from '@hindawi/ui'
import { Button, Title } from '@hindawi/phenom-ui'

function InfoPage(): ReactNode {
  const navigate = useNavigate()
  const { location } = window

  const pathState = get(location, 'state', {}) as {
    title: string
    content: string
    path: string
    buttonText: string
  }

  if (!pathState.title) {
    return <Navigate replace to="/" />
  }
  return (
    // @ts-ignore Should be migrated to the WhiteBox from @shared/ui
    <ShadowedBox centered mt={10} pb={6} pt={6}>
      <Title level={4} style={{ textAlign: 'center' }}>
        {pathState.title}
      </Title>
      <Row mt={2}>
        <Text>{pathState.content}</Text>
      </Row>

      {pathState.path && (
        <Row mt={4}>
          <Button onClick={() => navigate(pathState.path)} type="primary">
            {pathState.buttonText}
          </Button>
        </Row>
      )}
    </ShadowedBox>
  )
}

export default InfoPage

import { ApolloProvider } from '@apollo/react-components'
import LogRocket from 'logrocket'
import React from 'react'
import 'react-app-polyfill/ie11'
import 'react-app-polyfill/ie9'
import 'react-app-polyfill/stable'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'

import { theme } from '@hindawi/ui'
import { JournalProvider as HindawiContextProvider } from '../../component-journal-info'
import { ModalProvider } from '../../component-modal'
import apolloClientFactory from './apolloClientFactory'
import Root from './Root'
import RouteManager from './routes'

import publishers from '../../../config/publisher'

import { ErrorBoundary } from './ErrorBoundary'
import { TechnicalErrorPage } from './TechnicalErrorPage'
import './styles.css'

window.process = window.process || { env: null }

const CURRENT_PUBLISHER = 'wiley'

const render = () => {
  document.title = publishers[CURRENT_PUBLISHER].name
  ReactDOM.render(
    <ErrorBoundary fallback={<TechnicalErrorPage />}>
      <ModalProvider>
        <HindawiContextProvider publisher={publishers[CURRENT_PUBLISHER]}>
          <BrowserRouter>
            <ApolloProvider client={apolloClientFactory()}>
              <Root routes={<RouteManager />} theme={theme} />
            </ApolloProvider>
          </BrowserRouter>
        </HindawiContextProvider>
      </ModalProvider>
      <div id="ps-modal-root" style={{ height: 0 }} />
      <div id="portal-root" style={{ height: 0 }} />
    </ErrorBoundary>,
    document.getElementById('root'),
  )
}

const renderError = () => {
  ReactDOM.render(<TechnicalErrorPage />, document.getElementById('root'))
}

const initLogRocket = () => {
  if (process.env.LOGROCKET_ID) {
    LogRocket.init(process.env.LOGROCKET_ID)
    LogRocket.getSessionURL((sessionURL) => {
      Object.assign(window.appConfig || {}, { logRocketUrl: sessionURL })
    })
  }
}

const setQualtricsConfig = () => {
  window.appConfig = Object.assign(window.appConfig || {}, {
    appName: 'review',
    environment: process.env.APP_ENV,
  })
}

async function loadClientConfig() {
  return fetch('/client-config')
    .then((res) => res.json())
    .then((config) => {
      process.env = config
    })
}

loadClientConfig()
  .then(render)
  .then(setQualtricsConfig)
  .then(initLogRocket)
  .catch(renderError)

import React from 'react'
import { Footer } from './Footer'
import styled from 'styled-components'
import { AppBar, DownloadFileBar } from '../../component-authentication/client'

import { useJournal } from '../../component-journal-info'
import { KeycloakProvider } from '../../component-sso/client'
import { useLocation } from 'react-router-dom'

function HideOnPath({ component: Component, pathnames }) {
  const { pathname } = useLocation()
  if (pathnames.includes(pathname)) return null

  return <Component />
}

const App = ({ children }) => {
  const {
    supportEmail,
    links: { privacyLink, termsLink },
  } = useJournal()

  const hiddenAppBarPaths = ['/404', '/subscribe', '/unsubscribe']
  const hiddenFooterPaths = ['/404']
  const downloadReviewFilesPath = '/reviewFiles'
  const isDownloadReviewFilesPath = location.pathname.includes(
    downloadReviewFilesPath,
  )

  return (
    <Root>
      {/* Both <AppBar> and <PageContent> children need access to the Keycloak instance, so the provider cannot be nested deeper than this */}
      <KeycloakProvider>
        <HideOnPath
          component={() =>
            isDownloadReviewFilesPath ? <DownloadFileBar /> : <AppBar />
          }
          pathnames={hiddenAppBarPaths}
        />

        <PageContent>{children}</PageContent>

        <HideOnPath
          component={() => (
            <Footer
              privacyLink={privacyLink}
              supportEmail={supportEmail}
              termsLink={termsLink}
            />
          )}
          pathnames={hiddenFooterPaths}
        />
      </KeycloakProvider>
    </Root>
  )
}

export default App

const Root = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #f5f5f5;
  font-family: 'Nunito', sans-serif;
  font-size: 14px;
  color: #4f4f4f;
  line-height: 19px;
  min-height: 100vh;
`

const PageContent = styled.main`
  flex: 1;
`

import React from 'react'

import { Button, Flex, Text } from '@hindawi/phenom-ui'

export function Footer({
  supportEmail,
  privacyLink,
  termsLink,
}: {
  supportEmail: string
  privacyLink: string
  termsLink: string
}): React.JSX.Element {
  return (
    <Flex data-test-id="footer-row-id" justify="center" gap={'@spacer-2'}>
      <Button
        data-test-id="privacy-policy"
        type="tertiary"
        target="_blank"
        href={privacyLink}
      >
        Privacy Policy
      </Button>
      <Button
        data-test-id="terms-of-service"
        type="tertiary"
        target="_blank"
        href={termsLink}
      >
        Terms of Service
      </Button>
      <Flex ml={3} gap={'@spacer-1'}>
        <Text type="secondary">
          Support:{' '}
          <Button
            data-test-id="email-support"
            type="tertiary"
            href={`mailto:${supportEmail}`}
          >
            {supportEmail}
          </Button>
        </Text>
      </Flex>
    </Flex>
  )
}

import React, { ReactNode, useEffect, useState } from 'react'
import { Navigate, useNavigate } from 'react-router-dom'
import {
  keycloak as keycloakClient,
  useKeycloak,
} from '../../component-sso/client'

import { TechnicalErrorPage } from '../../app-review/app/TechnicalErrorPage'
import { LoadingState } from '../../app-review/app/LoadingState'
import { useCurrentUser } from './components/CurrentUserContext'
import { TrackedRoute } from './TrackedRoute'
import { UpdateAffiliationModal } from './UpdateAffiliationModal'

function checkIfUserHasRole(userRole: string, roles?: (string | boolean)[]) {
  if (roles && roles.includes(false)) {
    return false
  }

  if (!roles || !roles.length || roles.includes(true)) {
    return true
  }

  return roles.includes(userRole)
}

const AuthenticatedComponent = ({ component, ...rest }) => {
  const { keycloak } = useKeycloak()
  const currentUser = useCurrentUser()
  const navigate = useNavigate()

  useEffect(() => {
    if (currentUser?.pendingInvitation) {
      const invitationUrl = `/invitation/${currentUser.pendingInvitation.id}`
      if (location.pathname !== invitationUrl) {
        navigate(invitationUrl)
      }
    }
  }, [currentUser?.pendingInvitation])

  if (currentUser) {
    return (
      <>
        <TrackedRoute component={component} {...rest} />
        {!keycloak?.tokenParsed?.affiliation &&
          !/\/(invitation|emails)\//.test(location.pathname) && (
            /*
            In Connect, the affiliation is not configurable. It has to be done by the user after login.
            The Connect team is working to add the affiliation, so this modal is temporary.
            */
            <UpdateAffiliationModal
              userKeycloakId={keycloak?.tokenParsed?.sub}
            />
          )}
      </>
    )
  } else {
    throw new Error('Unknown error')
  }
}

export function ProtectedRoute({
  allow,
  children,
}: {
  children: ReactNode
  allow?: (string | boolean)[]
}): ReactNode {
  const { keycloak, setKeycloak, loading } = useKeycloak()
  const [keycloakError, setKeycloakError] = useState(null)
  const [keycloakLoading, setKeycloakLoading] = useState(true)
  const currentUser = useCurrentUser()

  useEffect(() => {
    if (keycloak === null) {
      const keycloakEnv = process.env.KEYCLOAK
      setKeycloakLoading(true)
      void keycloakClient.init(
        keycloakEnv,
        (keycloak) => {
          if (setKeycloak) {
            setKeycloak(keycloak)
          }
          setKeycloakLoading(false)
        },
        (error) => {
          setKeycloakError(error)
          setKeycloakLoading(false)
        },
      )
    } else {
      setKeycloakLoading(false)
    }
  }, [])

  if (keycloakLoading) {
    return <LoadingState />
  } else if (keycloakError) {
    return <TechnicalErrorPage />
  } else if (keycloak) {
    if (!keycloak.authenticated || keycloak.isTokenExpired()) {
      void keycloak.login({ redirectUri: location?.href })
      return null
    } else if (loading) {
      return <LoadingState />
    } else if (checkIfUserHasRole(currentUser?.role, allow)) {
      return <AuthenticatedComponent component={children} />
    } else {
      return <Navigate replace to="/" />
    }
  } else {
    return <TechnicalErrorPage />
  }
}

export default ProtectedRoute

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { compose, setDisplayName, withHandlers } from 'recompose'
import { Button, Spinner, Title } from '@hindawi/phenom-ui'

import { Icon, Row, Text, withFetching } from '../../component-hindawi-ui'

const MultiAction = ({
  title,
  content,
  onClose,
  subtitle,
  onConfirm,
  isFetching,
  cancelText,
  confirmText,
  fetchingError,
  renderContent,
  confirmButtonExtraProps = {},
  cancelButtonExtraProps = {},
}) => (
  <Root>
    <Icon
      bold
      color="#DBDBDB"
      fontSize="24px"
      icon="remove1"
      onClick={onClose}
      right={8}
      secondary
      top={12}
    />
    <Title level={4} style={{ textAlign: 'center' }}>
      {title}
    </Title>
    {subtitle && (
      <Text align="center" secondary>
        {subtitle}
      </Text>
    )}
    {renderContent()}
    {fetchingError && (
      <Text align="center" error>
        {fetchingError}
      </Text>
    )}
    <Row justify={isFetching ? 'center' : 'space-between'} mt={6}>
      {isFetching ? (
        <Spinner size="small" />
      ) : (
        <RowAlignRight>
          <Button
            data-test-id="modal-confirm"
            onClick={onConfirm}
            style={{ marginLeft: '12px', width: 'auto' }}
            type="primary"
            {...confirmButtonExtraProps}
          >
            {confirmText}
          </Button>
          <Button
            data-test-id="modal-cancel"
            onClick={onClose}
            {...cancelButtonExtraProps}
            type="primary"
            style={{ width: 'auto' }}
          >
            {cancelText}
          </Button>
        </RowAlignRight>
      )}
    </Row>
  </Root>
)

MultiAction.propTypes = {
  /** Title that will be showed on the card. */
  title: PropTypes.string,
  /** Subtitle that will be showed on the card. */
  subtitle: PropTypes.string,
  /** The text you want to see on the button when someone submit the report. */
  confirmText: PropTypes.string,
  /** The text you want to see on the button when someone cancel the report. */
  cancelText: PropTypes.string,
  /** Callback function fired when cancel confirmation card. */
  onCancel: PropTypes.func,
  /** Callback function fired when confirm confirmation card. */
  onConfirm: PropTypes.func,
  /** When is true will show a spinner.  */
  onClose: PropTypes.func,
  /** Callback function fired when you want to close the card. */
  isFetching: PropTypes.bool,
  /** The component you want to show on the card. */
  content: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
    PropTypes.string,
  ]),
}
MultiAction.defaultProps = {
  title: undefined,
  subtitle: undefined,
  confirmText: 'OK',
  cancelText: 'Cancel',
  onCancel: undefined,
  onConfirm: undefined,
  onClose: undefined,
  isFetching: undefined,
  content: undefined,
}

export default compose(
  withFetching,
  withHandlers({
    onConfirm:
      ({ onConfirm, ...props }) =>
      () => {
        if (typeof onConfirm === 'function') {
          onConfirm(props)
        } else {
          props.hideModal()
        }
      },
    onClose:
      ({ onCancel, ...props }) =>
      () => {
        if (typeof onCancel === 'function') {
          onCancel(props)
        }
        props.hideModal()
      },
    renderContent:
      ({ content, ...props }) =>
      () => {
        if (!content) return null
        if (typeof content === 'object') {
          return content
        } else if (typeof content === 'function') {
          return content(props)
        }
        return <Text dangerouslySetInnerHTML={{ __html: content }} mb={2} />
      },
  }),
  setDisplayName('MultiActionModal'),
)(MultiAction)

// #region styles
const Root = styled.div`
  align-items: center;
  background: #ffffff;
  border: 1px solid transparent;
  border-radius: 4px;
  box-shadow: 0 1px 2px 1px #dbdbdb;
  display: flex;
  flex-direction: column;
  position: relative;
  padding: calc(4px * 10);
  padding-bottom: calc(4px * 6);
  width: calc(4px * 130);
`

const RowAlignRight = styled.div`
  display: flex;
  flex-direction: row-reverse;
  width: calc(4px * 130);
`

// #endregion

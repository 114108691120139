import React, { ReactNode } from 'react'
import { FourOFour } from '@hindawi/phenom-ui'
import { useJournal } from '../../component-journal-info'
import { useNavigate } from 'react-router-dom'

const ACTION_NAME = 'GO TO DASHBOARD'
const SUPPORT_TEXT = 'In case of any urgent situation contact '

function FourOFourPage(): ReactNode {
  const navigate = useNavigate()
  const { supportEmail } = useJournal()
  const goToDashboard = () => navigate('/')

  return (
    <FourOFour
      supportEmail={supportEmail}
      actionButton={{
        name: ACTION_NAME,
        action: goToDashboard,
      }}
      websiteURL={''}
      supportText={SUPPORT_TEXT}
    />
  )
}

export default FourOFourPage

import { createContext } from 'react'
//@ts-ignore
import type Keycloak from 'keycloak-js'

export type KeycloakT =
  | (Keycloak & {
      forceUpdateToken: () => Promise<void>
    })
  | null

export default createContext<{
  keycloak: KeycloakT
  loading: boolean
  setKeycloak: ((_: KeycloakT) => void) | null
}>({ keycloak: null, setKeycloak: null, loading: false })

export const KEYCLOAK_ROLES = Object.freeze({
  ADMIN: 'admin',
  EA: 'editorial_assistant',
  AE: 'academic_editor',
  JM: 'journal_manager',
  TE: 'triage_editor',
})

export const ROLES = Object.freeze({
  USER: 'user',
  ADMIN: 'admin',
  AUTHOR: 'author',
  REVIEWER: 'reviewer',
  TE: 'triageEditor',
  AE: 'academicEditor',
  EA: 'editorialAssistant',
  SUBMITTING_STAFF_MEMBER: 'submittingStaffMember',
})

export const ROLES_LABELS = ({
  academicEditorLabel,
  triageEditorLabel,
}: {
  academicEditorLabel?: string
  triageEditorLabel?: string
}): Record<string, string> => {
  return Object.freeze({
    [ROLES.ADMIN]: 'Admin',
    [ROLES.AUTHOR]: 'Author',
    [ROLES.REVIEWER]: 'Reviewer',
    [ROLES.TE]: triageEditorLabel || 'Triage Editor',
    [ROLES.AE]: academicEditorLabel || 'Academic Editor',
    [ROLES.EA]: 'Editorial Assistant',
  })
}

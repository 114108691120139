 

import React, { Component } from 'react'
import { isEqual } from 'lodash'
import PropTypes from 'prop-types'
import { graphql } from 'react-apollo'
import styled from 'styled-components'
import {
  IconCircleCheck,
  IconNotificationError,
  Spinner,
  Text,
} from '@hindawi/phenom-ui'
import {
  compose,
  withProps,
  setDisplayName,
  withStateHandlers,
} from 'recompose'

import { autosaveState } from '../graphql/queries'

class AutosaveIndicator extends Component {
  constructor(props) {
    super(props)
    this.timer = null
  }

  componentDidMount() {
    this.setTimer()
  }

  UNSAFE_componentWillReceiveProps({ autosave: nextAutosave }) {
    const { autosave, showIndicator } = this.props
    if (!isEqual(autosave, nextAutosave)) {
      showIndicator()
      this.setTimer()
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timer)
  }

  setTimer = () => {
    const { hideIndicator, delay = 2000 } = this.props
    if (this.timer) {
      clearTimeout(this.timer)
    }
    this.timer = setTimeout(hideIndicator, delay)
  }

  render() {
    const {
      isVisible,
      successText = 'Changes saved',
      errorText = 'Changes not saved',
      progressText = 'Saving changes...',
      autosave: { inProgress, error, updatedAt },
    } = this.props
    return isVisible ? (
      <Root className="autosave-indicator">
        {!inProgress && !updatedAt && (
          <AutoSaveContainer>
            <Spinner icon="loader" size={4} />
            <Text>{progressText}</Text>
          </AutoSaveContainer>
        )}

        {!inProgress && updatedAt && (
          <AutoSaveContainer>
            <IconContainer>
              <IconCircleCheck style={{ color: '#81ba40' }} />
            </IconContainer>
            <Text>{successText}</Text>
          </AutoSaveContainer>
        )}
        {!inProgress && error && (
          <AutoSaveContainer>
            <IconContainer>
              <IconNotificationError />
            </IconContainer>
            <Text error title={error}>
              {errorText}
            </Text>
          </AutoSaveContainer>
        )}
      </Root>
    ) : (
      <span />
    )
  }
}

export default compose(
  graphql(autosaveState),
  withProps(({ data }) => ({
    autosave: data.autosave,
  })),
  withStateHandlers(
    { isVisible: false },
    {
      showIndicator: () => () => ({
        isVisible: true,
      }),
      hideIndicator: () => () => ({
        isVisible: false,
      }),
    },
  ),
  setDisplayName('AutosaveIndicator'),
)(AutosaveIndicator)

AutosaveIndicator.propTypes = {
  autosave: PropTypes.shape({
    inProgress: PropTypes.bool,
    error: PropTypes.string,
    updatedAt: PropTypes.number,
  }),
  delay: PropTypes.number,
  successText: PropTypes.string,
}

AutosaveIndicator.defaultProps = {
  autosave: {
    inProgress: false,
    error: '',
    updatedAt: undefined,
  },
  delay: 3000,
  successText: 'Progress Saved',
}

// #region styles
const Root = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;
`
const AutoSaveContainer = styled.div`
  align-items: center;
  display: flex;
  padding: calc(4px * 2);
`
const IconContainer = styled.div`
  margin-right: 2px;
  align-items: center;
  display: flex;
  justify-content: center;
`
// #endregion

import React, { useState, useEffect, Fragment } from 'react'
import {
  Row,
  Col,
  Typography,
  IconCaretDown,
  IconCaretUp,
  Text,
  Title,
  Space,
  Tooltip,
  Tag,
  Flex,
} from '@hindawi/phenom-ui'
import { AuthorTagList } from '@shared/ui'
import { Card, Content, Footer, Label } from './styles'
import { getStatusCategory, ManuscriptStatus } from '../utils'
import { RevisionDeadlineMessage } from '../../../../../component-peer-review/client/components/manuscriptDetails/RevisionDeadlinesMessage'
import { Author } from '@shared/ui/types'
import { PeerReviewEditorialMappingT } from '../EditorialManuscriptCard/types'
import { LeftLine } from '../EditorialManuscriptCard/components'
import { StatusTag } from '@shared/ui'
import { useDateParser } from '@shared/ui/hooks'

const { Link } = Typography

type MemberT = {
  id: string
  status: string
  alias: {
    email: string
    name: {
      givenNames: string
      surname: string
    }
  }
} | null

enum WaiverType {
  WAIVER_CODE = 'waiverCode',
  APC_OVERRIDE = 'apcOverride',
}

type WaiverApplied = {
  type: WaiverType
  reason: string | null
  otherReason: string | null
} | null

const stopPropagation = (e) => e.stopPropagation()
interface ManuscriptCardProps {
  manuscript: {
    customId: string
    meta: {
      title: string | undefined
    }
    journal?: {
      name: string | undefined
    }
    articleType?: {
      name: string | undefined
    }
    sourceJournalId?: string | undefined
    commissionInvitationId?: string | undefined
    version: string
    visibleStatus: string
    status: ManuscriptStatus
    updated: string
    submittedDate: string
    academicEditor: MemberT
    editorialAssistant: MemberT
    triageEditor: MemberT
    authors: Author[]
    specialIssue: { name: string }
    section: { name: string }
    reviewers: { status: string }[]
    editors: { status: string; role: string }[]
    expanded: boolean
    peerReviewModel?: {
      triageEditorLabel: string | undefined
      academicEditorLabel: string | undefined
    }
    peerReviewEditorialMapping?: PeerReviewEditorialMappingT
    revisionDeadlineDate?: Date
    currentUserRole?: string
    waiverApplied: WaiverApplied
  }
  onClick: () => void
}

const renderEditorsCells = ({
  triageEditor,
  triageEditorLabel,
  academicEditor,
  academicEditorLabel,
}) =>
  [
    {
      title: triageEditorLabel,
      data: triageEditor,
    },
    {
      title: academicEditorLabel,
      data: academicEditor,
    },
  ].map(({ title, data }) => (
    <Col key={title} onClick={stopPropagation} span={4}>
      <Label>{title}</Label>
      <div>
        {!data ? (
          'Unassigned'
        ) : (
          <Tooltip
            copyContent={data.alias.email}
            showCopyContent
            replaceTooltipText="Editor name"
          >
            <Link>
              {data.alias.name.givenNames} {data.alias.name.surname}
            </Link>
          </Tooltip>
        )}
      </div>
    </Col>
  ))

const renderShowMore = ({
  section,
  reviewers,
  academicEditors,
  specialIssue,
}) => (
  <>
    <Row gutter={[16, 12]}>
      {section && (
        <Col xl={4} xxl={8}>
          <Label>Section</Label>
          <Text ellipsis>{section.name}</Text>
        </Col>
      )}
      <Col xl={10} xxl={8}>
        <Label>Editor Invites</Label>
        <div>
          <Space size={4}>
            <Label>{academicEditors.length}</Label>
            <span>Invited,</span>
            <Label>
              {
                academicEditors.filter((editor) =>
                  ['accepted', 'active', 'removed'].includes(editor.status),
                ).length
              }
            </Label>
            <span>Agreed,</span>
            <Label>
              {
                academicEditors.filter((editor) => editor.status === 'declined')
                  .length
              }
            </Label>
            <span>Declined,</span>
            <Label>
              {
                academicEditors.filter((editor) => editor.status === 'pending')
                  .length
              }
            </Label>
            <span>Pending</span>
          </Space>
        </div>
      </Col>
      <Col xl={10} xxl={8}>
        <Label>Reviewer Reports</Label>
        <div>
          <Space size={4}>
            <Label>{reviewers.length}</Label>
            <span>Invited,</span>
            <Label>
              {
                reviewers.filter((reviewer) => reviewer.status === 'accepted')
                  .length
              }
            </Label>
            <span>Agreed,</span>
            <Label>
              {
                reviewers.filter((reviewer) => reviewer.status === 'declined')
                  .length
              }
            </Label>
            <span>Declined,</span>
            <Label>
              {
                reviewers.filter((reviewer) => reviewer.status === 'submitted')
                  .length
              }
            </Label>
            <span>Submitted</span>
          </Space>
        </div>
      </Col>
    </Row>
    {specialIssue && (
      <Row gutter={[16, 12]}>
        <Col>
          <Label>Special Issue</Label>
          <Text ellipsis>{specialIssue.name}</Text>
        </Col>
      </Row>
    )}
  </>
)

export const ManuscriptCard: React.FC<ManuscriptCardProps> = ({
  manuscript: {
    customId,
    meta: { title = 'No Title' },
    journal = {},
    articleType = {},
    sourceJournalId,
    commissionInvitationId,
    version,
    visibleStatus,
    status,
    academicEditor,
    triageEditor,
    authors = [],
    submittedDate,
    updated,
    specialIssue,
    section,
    reviewers = [],
    editors = [],
    expanded,
    peerReviewModel = {},
    revisionDeadlineDate,
    currentUserRole,
    editorialAssistant,
    waiverApplied = {
      type: WaiverType.WAIVER_CODE,
      otherReason: null,
      reason: null,
    },
    peerReviewEditorialMapping = {
      peerReviewEditorialModel: {
        triageEditor: {
          label: null,
        },
        academicEditor: {
          label: null,
        },
      },
    },
  },
  onClick,
}) => {
  const [showMore, setShowMore] = useState(expanded)

  useEffect(() => {
    setShowMore(expanded)
  }, [expanded])

  reviewers = reviewers || []
  const academicEditors =
    editors?.filter((editor) => editor.role == 'academicEditor') || []

  const { timestamp: submittedDateTimestamp, timeAgo: submittedDateTimeAgo } =
    useDateParser({
      timestamp: submittedDate,
      humanizeThreshold: 0,
    })

  const { timestamp: updatedTimestamp, timeAgo: updatedTimeAgo } =
    useDateParser({
      timestamp: updated,
      humanizeThreshold: 0,
    })

  return (
    <Flex width="full" minHeight={185} maxHeight={560}>
      <LeftLine statusColor={getStatusCategory(status)} />
      <Card
        onClick={onClick}
        statusColor={getStatusCategory(status)}
        old={false}
      >
        <Content data-test-id="search-result-manuscript-card">
          <Row gutter={[16, 12]}>
            <Col mr={16} flex="none" onClick={stopPropagation}>
              <Label>ID</Label>
              <Tooltip
                copyContent={customId}
                showCopyContent
                replaceTooltipText="Custom ID"
              >
                <Link>{customId}</Link>
              </Tooltip>
              {commissionInvitationId && (
                <Tag
                  label="commissioned"
                  style={{ marginLeft: '8px' }}
                  outline
                  data-test-id="commissioned-tag"
                />
              )}
              {sourceJournalId && (
                <Tag
                  label="transferred"
                  style={{ marginLeft: '8px' }}
                  outline
                  data-test-id="transferred-tag"
                />
              )}
              {waiverApplied && (
                <Tag
                  label={
                    waiverApplied?.type === WaiverType.WAIVER_CODE
                      ? 'Waiver applied'
                      : 'APC Override Requested'
                  }
                  outline
                  priority="positive"
                  style={{ marginLeft: '8px' }}
                  data-test-id="goa-tag"
                />
              )}
            </Col>
            <Col flex="auto" className="col-oh">
              <Label>
                <Title
                  data-test-id="manuscript-title"
                  ellipsis={{ rows: 1 }}
                  level={5}
                >
                  {title}
                </Title>
              </Label>
            </Col>
            <Col onClick={stopPropagation} mr={16}>
              <RevisionDeadlineMessage
                editorialAssistant={editorialAssistant}
                currentUserRole={currentUserRole}
                revisionDeadlineDate={revisionDeadlineDate}
              />
            </Col>
            <Col>
              <StatusTag
                visibleStatus={visibleStatus}
                status={status}
                version={version}
              />
            </Col>
          </Row>

          <Row gutter={[16, 12]}>
            {journal && (
              <Fragment>
                <Col span={8}>
                  <Label>Journal</Label>
                  <Text data-test-id="journal-name" ellipsis>
                    {journal.name}
                  </Text>
                </Col>

                {renderEditorsCells({
                  triageEditor,
                  triageEditorLabel:
                    peerReviewEditorialMapping?.peerReviewEditorialModel
                      ?.triageEditor?.label ||
                    peerReviewModel.triageEditorLabel,
                  academicEditor,
                  academicEditorLabel:
                    peerReviewEditorialMapping?.peerReviewEditorialModel
                      ?.academicEditor?.label ||
                    peerReviewModel.academicEditorLabel,
                })}
              </Fragment>
            )}
            {authors && authors.length && (
              <Col span={8}>
                <Label>Authors</Label>
                <AuthorTagList authors={authors} withTooltip />
              </Col>
            )}
          </Row>

          {showMore &&
            renderShowMore({
              section,
              reviewers,
              academicEditors,
              specialIssue,
            })}

          <Row gutter={[16, 12]}>
            <Col>
              <div onClick={stopPropagation}>
                <Link onClick={() => setShowMore(!showMore)}>
                  <Space>
                    {showMore ? <IconCaretUp /> : <IconCaretDown />}
                    <span>Show {showMore ? 'less' : 'more'} details</span>
                  </Space>
                </Link>
              </div>
            </Col>
          </Row>
        </Content>

        <Footer>
          <Row gutter={[24, 0]}>
            {submittedDate && status !== 'draft' && (
              <Col>
                <Label> Submitted date </Label>
                <Text>{`${submittedDateTimestamp} (${submittedDateTimeAgo})`}</Text>
              </Col>
            )}

            {updated && (
              <Col>
                <Label> Updated on </Label>
                <Text>{`${updatedTimestamp} (${updatedTimeAgo})`}</Text>
              </Col>
            )}

            {articleType && articleType.name && (
              <Col>
                <Label> Article Type </Label>
                <span>{articleType.name}</span>
              </Col>
            )}
          </Row>
        </Footer>
      </Card>
    </Flex>
  )
}

import React from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

// https://reactrouter.com/6.28.0/start/faq#what-happened-to-withrouter-i-need-it
export default function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    const location = useLocation()
    const navigate = useNavigate()
    const params = useParams()
    return <Component {...props} router={{ location, navigate, params }} />
  }

  return ComponentWithRouterProp
}

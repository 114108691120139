import React from 'react'
import { space } from 'styled-system'
import styled from 'styled-components'
import { Text } from '@hindawi/ui'
import Fade from './Fade'

import { useFileDownload } from '../decorators'
import { fileHasPreview, parseFileSize } from '../fileUtils'
import {
  IconDelete,
  IconDownload,
  IconPreview,
  Spinner,
  Space as _Space,
} from '@hindawi/phenom-ui'

const Space = styled(_Space)`
  margin-right: 12px;
  margin-left: 12px;
`

const FileLayout = ({
  onDelete,
  hasDelete = false,
  item: file,
  dragHandle = null,
  onPreview = () => () => {},
  ...rest
}) => {
  const { isFetching, downloadFile, fetchingError } = useFileDownload(file)
  const fileSize = parseFileSize(file)
  const hasPreview = fileHasPreview(file)
  return (
    <Root data-test-id={`file-${file.id}`} {...rest}>
      {typeof dragHandle === 'function' ? dragHandle() : dragHandle}
      <FileInfo>
        <FileName>{file.filename}</FileName>
        <FileSize ml={2}>{fileSize}</FileSize>
      </FileInfo>
      <Space size={16}>
        {hasPreview && (
          <IconPreview
            onClick={onPreview(file)}
            data-test-id={`${file.id}-preview`}
          />
        )}

        {isFetching ? (
          <Spinner size="xsmall" />
        ) : (
          <IconDownload
            onClick={downloadFile}
            data-test-id={`${file.id}-preview`}
          />
        )}

        {hasDelete && (
          <IconDelete onClick={onDelete} data-test-id={`${file.id}-delete`} />
        )}
      </Space>
      {fetchingError && (
        <Fade>
          <ErrorWrapper>
            <Text error>{fetchingError}</Text>
          </ErrorWrapper>
        </Fade>
      )}
    </Root>
  )
}

export default FileLayout

// #region styles
const Root = styled.div`
  align-items: center;
  background-color: ${({ grey }) => (grey ? '#F5F5F5' : 'white')};
  border-radius: 4px;
  display: flex;
  border: 1px solid #e0e0e0;
  height: calc(4px * 8);
  position: relative;
  white-space: nowrap;
  ${space};
`

const ErrorWrapper = styled.div`
  position: absolute;
  top: calc(4px * 10);
  left: 0;

  width: calc(4px * 40);
`

const FileInfo = styled.div`
  align-items: center;
  border-right: 1px solid #e0e0e0;
  font-family: 'Nunito';
  display: flex;
  height: inherit;
  flex: 1;
  justify-content: space-between;
  margin-left: calc(4px * 2);
  color: #333333;
`

const FileName = styled(Text)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const FileSize = styled.span`
  font-weight: 700;
  margin: 0 calc(4px * 2);
  color: #4f4f4f;
`
// #endregion
